import { render, staticRenderFns } from "./OrganismBuildingLabels.vue?vue&type=template&id=1949849a&scoped=true&"
import script from "./OrganismBuildingLabels.vue?vue&type=script&lang=js&"
export * from "./OrganismBuildingLabels.vue?vue&type=script&lang=js&"
import style0 from "./OrganismBuildingLabels.vue?vue&type=style&index=0&id=1949849a&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1949849a",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {MoleculesProjectBuildingV2MoleculePhaseLabel: require('/app/components/molecules/project/buildingV2/MoleculePhaseLabel.vue').default,MoleculesProjectBuildingV2MoleculeBuildingLabel: require('/app/components/molecules/project/buildingV2/MoleculeBuildingLabel.vue').default,MoleculesProjectBuildingV2MoleculeBuildingFloorLabel: require('/app/components/molecules/project/buildingV2/MoleculeBuildingFloorLabel.vue').default,MoleculesProjectBuildingV2MoleculeBuildingSelectedSpaceDetails: require('/app/components/molecules/project/buildingV2/MoleculeBuildingSelectedSpaceDetails.vue').default})
