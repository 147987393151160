
export default {
  name: 'Organism3DContainer',
  components: {},
  props: {
    containerData: {
      required: true,
      type: Object || Array,
      default: function () {
        return {
          backgroundLoader: '',
          contentLoader: [],
          videosLoader: []
        }
      }
    }
  },
  data() {
    return {}
  },
  computed: {
    backgroundLoaderUrl() {
      return this.containerData?.backgroundLoader
    },
    contentLoaderData() {
      return this.containerData?.contentLoader || []
    },
    videosLoader() {
      return this.containerData?.videosLoader || []
    },
    loadingErrorOccurred() {
      return this.$store.state.building.loadingErrorOccurred
    }
  },
  watch: {},
  mounted() {},
  methods: {}
}
