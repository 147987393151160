
import requestOfferConstants from '~/store/requestOffer/-constants'
import modalConstants from '~/store/modal/-constants'

export default {
  name: 'OrganismCartSpacesBuilding',
  props: {},
  data() {
    return {}
  },
  computed: {
    buildingStore() {
      return this.$store.state.building
    },
    requestedSpaces() {
      return this.$store.state.requestOffer.spaces
    },
    spacesCounter() {
      return this.$store.state.requestOffer.spaces.length
    },
    isMenuOpen() {
      return this.$store.state.base.isAvailabilityMenuOpen
    },
    hasSpaceSelected() {
      return !!this.buildingStore.space.spaceData
    },
    buildings() {
      return this.$store.state.project.project.buildings
    },
    floors() {
      return this.$store.state.project.project.floors
    },
    activeProject() {
      return this.$store.state.project.activeProject
    },
    projectFeatures() {
      return this.$store.getters?.featuresForProject(this.activeProject)
    },
    disableList() {
      return this.projectFeatures?.disableMyList
    },
    listSpaceDetails() {
      return this.projectFeatures?.listSpaceDetails || ['sqm', 'people']
    }
  },
  methods: {
    spaceIsRequested(spaceId) {
      return this.requestedSpaces.find((space) => space.id === spaceId)
    },
    openRequestOfferModal() {
      if (!this.spacesCounter) return
      if (window && 'analyticsLayer' in window) {
        window.analyticsLayer.send({
          event: 'request_offer_open',
          payload: {
            zone: 'building'
          }
        })
      }
      this.$store.dispatch(modalConstants.withNamespace(modalConstants.action.CHANGE_MODAL_STATE), {
        showModal: true,
        modalType: 'request-offer'
      })
      document.body.classList.add('disable-scroll')
    }
  }
}
