import { render, staticRenderFns } from "./OrganismBuildingLoader.vue?vue&type=template&id=8e9f6930&scoped=true&"
import script from "./OrganismBuildingLoader.vue?vue&type=script&lang=js&"
export * from "./OrganismBuildingLoader.vue?vue&type=script&lang=js&"
import style0 from "./OrganismBuildingLoader.vue?vue&type=style&index=0&id=8e9f6930&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "8e9f6930",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {OrganismsProjectOrganismLoader: require('/app/components/organisms/project/OrganismLoader.vue').default})
