
import { Fragment } from 'vue-frag'
import baseConstants from '~/store/base/-constants'
import { isMobile, isTablet } from '~/helpers/mobile/DeviceType'

export default {
  name: 'OrganismProjectAvailabilityOptions',
  components: {
    Fragment
  },
  props: {},
  data() {
    return {
      isPhoneOrTablet: isMobile() || isTablet()
    }
  },
  computed: {
    storeFilters() {
      return this.$store.state.availability.filters
    },
    isFilterOpen() {
      return this.$store.state.base.isFilterOpen
    },
    isSidebarMobileOpen() {
      return this.$store.state.base.isSidebarMobileOpen
    },
    isMenuOpen() {
      return this.$store.state.base.isAvailabilityMenuOpen
    },
    engine3d() {
      return this.$engine3d || window.engine3d
    },
    clientManager() {
      return this.engine3d?.getClientManager()
    },
    activeProject() {
      return this.$store.state.project.activeProject
    },
    projectFeatures() {
      return this.$store.getters?.featuresForProject(this.activeProject)
    },
    activeProjectData() {
      return this.$store.state.project.projects.find((p) => p.slug === this.activeProject)
    },
    disableList() {
      return this.projectFeatures?.disableMyList
    },
    dataType() {
      let type = 'project'
      if (this.storeFilters.phaseId) {
        type = 'phase'
      }
      if (this.storeFilters.buildingId) {
        type = 'building'
      }
      if (typeof this.storeFilters.floorNo !== 'undefined' || this.storeFilters.floorId) {
        type = 'floor'
      }
      return type
    },
    results() {
      switch (this.dataType) {
        case 'project':
          return this.$store.state.availability.project
        case 'building':
          return this.$store.state.availability.building
        case 'floor':
          return this.$store.state.availability.floor
        default:
          return []
      }
    },
    hasResults() {
      return this.results.length > 0
    },
    selectedSpace() {
      return this.$store.state.building.space.spaceData
    },
    buildingData() {
      return this.$store.state.project.project.buildings.find(
        (b) => b.id === this.storeFilters.buildingId
      )
    },
    buildingName() {
      return this.buildingData?.name || this.activeProjectData?.name || this.$t('allBuildings')
    }
  },
  mounted() {
    document
      .getElementById('organism-sidebar-project')
      ?.addEventListener('scroll', this.scrollEventListener)
  },
  beforeDestroy() {
    document
      .getElementById('organism-sidebar-project')
      ?.removeEventListener('scroll', this.scrollEventListener)
  },
  methods: {
    toggleSidebarMobile() {
      this.$store.dispatch(baseConstants.withNamespace(baseConstants.action.TOGGLE_SIDEBAR_MOBILE))
    },
    scrollEventListener(e) {
      const target = e.target
      if (target.scrollTop > 155 && this.isFilterOpen) {
        this.$store.dispatch(
          baseConstants.withNamespace(baseConstants.action.CHANGE_FILTER_V2_STATE),
          false
        )
      }
    }
  }
}
