
import { CurrentView } from '~/store/building/-constants'
import universeConstants from '~/store/universe/-constants'

export default {
  name: 'OrganismTutorial',
  mouted() {
    this.$store.subscribe((payload, state) => {
      this.isFloorView = state.currentView === CurrentView.FLOOR
    })
  },
  data() {
    return {
      isFloorView: false
    }
  },
  computed: {
    open() {
      return this.$store.state?.universe?.infoBox?.open || false
    },
    escInfoHideMobile() {
      if (!process.browser) {
        return false
      }
      return window.innerWidth < 1200
    }
  },
  methods: {
    cdnImage(image) {
      return this.$store.getters['base/cdnBase'] + image
    },
    handleClose() {
      this.$store.dispatch(
        universeConstants.withNamespace(universeConstants.action.TOGGLE_INFO_BOX),
        {
          open: false
        }
      )
    }
  }
}
