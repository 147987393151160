import { render, staticRenderFns } from "./OrganismSpaceDetailsV2.vue?vue&type=template&id=6da76fd4&"
import script from "./OrganismSpaceDetailsV2.vue?vue&type=script&lang=js&"
export * from "./OrganismSpaceDetailsV2.vue?vue&type=script&lang=js&"
import style0 from "./OrganismSpaceDetailsV2.vue?vue&type=style&index=0&id=6da76fd4&prod&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* custom blocks */
import block0 from "@/lang/OrganismSpaceDetails.json?vue&type=custom&index=0&blockType=i18n&issuerPath=%2Fapp%2Fcomponents%2Forganisms%2Fproject%2FbuildingV2%2FOrganismSpaceDetailsV2.vue"
if (typeof block0 === 'function') block0(component)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {AtomsCommonAtomIcon: require('/app/components/atoms/common/AtomIcon.vue').default,AtomsCommonAtomButton: require('/app/components/atoms/common/AtomButton.vue').default,OrganismsCommonOrganismDrawer: require('/app/components/organisms/common/OrganismDrawer.vue').default})
