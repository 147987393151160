
import { Config3D, LOAD_TYPE } from '@bright-spaces/engine-3d/dist/helpers'
import { Client3DManager } from '@bright-spaces/engine-3d/dist/client/Client3DManager'
import { CurrentView, ProjectFocusMode } from '~/store/building/-constants'
import { CLIENT_CONFIG_3D } from '~/helpers/ClientUtils'
import baseConstants from '~/store/base/-constants'
import { getElementHalfWidth } from '~/helpers/util'
import availabilityConstants from '~/store/availability/-constants'
import projectConstants from '~/store/project/-constants'
import guidedTourConstants from '@/store/guidedTour/-constants'
import { WebRequest } from '~/helpers/api'

export default {
  name: 'OrganismBuilding',
  components: {},
  props: {
    customModel: {
      required: false,
      type: Object,
      default: function () {
        return {}
      }
    }
  },
  data() {
    return {
      windowHeight: typeof window !== 'undefined' ? window.innerHeight : 0,
      sidebarHeight: 0,
      scrollButtonHeight: 0,
      canvasHeight: 0,
      pinData: []
    }
  },
  computed: {
    isInitialized() {
      return this.$store.state.building.isInitialized
    },
    cdnBase() {
      return this.$store.getters.cdnBase
    },
    activeProject() {
      return this.$store.state.project.activeProject
    },
    isFloorView() {
      return this.$store.state.building.currentView === CurrentView.FLOOR
    },
    buildings() {
      return this.$store.state.project.project.buildings
    },
    generalConfig() {
      return this.$store.state.base.meta.generalConfig
    },
    projectSettings() {
      return this.$store.getters.getActiveProjectSettings
    },
    engine3d() {
      return this.$engine3d || window.engine3d
    },
    pinConfigBuilding() {
      return this.projectSettings?.building?.pinsData || []
    },
    pinConfigFloor() {
      return this.projectSettings?.floor?.pinsData || []
    },
    pinConfig() {
      return this.isFloorView ? this.pinConfigFloor : this.pinConfigBuilding
    },
    htmlPinsEnabled() {
      return this.isFloorView
        ? this.projectSettings?.floor?.htmlPins || false
        : this.projectSettings?.building?.htmlPins || false
    },
    hasPins() {
      if (!this.htmlPinsEnabled) return false
      return this.pinConfig && this.pinConfig.pins && this.pinConfig.pins.length > 0
    },
    isMenuOpen() {
      return this.$store.state.base.isAvailabilityMenuOpen
    },
    projectFocusMode() {
      return this.$store.state.building.projectFocusMode
    },
    isBuildingFocus() {
      return this.projectFocusMode === ProjectFocusMode.BUILDING
    },
    isFloorFocus() {
      return this.projectFocusMode === ProjectFocusMode.FLOOR
    },
    isSpaceFocus() {
      return this.projectFocusMode === ProjectFocusMode.SPACE
    },
    phases() {
      return this.$store.state.project.project.phases
    },
    isPhaseFocus() {
      return this.projectFocusMode === ProjectFocusMode.PHASE
    }
  },
  watch: {
    isInitialized: function (value) {
      if (value) {
        window.addEventListener('resize', this.resizeListener)
      }
    },
    $route: {
      deep: true,
      handler: function (to, from) {
        // eslint-disable-next-line require-await
        this.$nextTick(async () => {
          this.initializeObserver()
        })
      }
    }
  },
  mounted() {
    window.addEventListener('keydown', this.changeBuildingViewOnEscape)
    this.resizeListener()

    // eslint-disable-next-line require-await
    this.$nextTick(async () => {
      this.initializeObserver()
    })
  },
  beforeDestroy() {
    this.$store.dispatch('building/destroy')
    this.engine3d && this.engine3d.default().destroy()
    window.removeEventListener('resize', this.resizeListener)
    window.removeEventListener('keydown', this.changeBuildingViewOnEscape)
  },
  methods: {
    async getAllProjectSpaces() {
      const { data } = await WebRequest.GET(
        this.$webApi.availability.spaceAvailability({
          return_not_available: true,
          project_id: this.$store.getters.getActiveProjectData.id
        })
      )
      const { result, error } = data
      if (error) {
        return []
      }
      return result
    },
    changeBuildingViewOnEscape(e) {
      if (['Escape'].includes(e.code)) {
        if (this.isBuildingFocus) {
          this.$store.dispatch(
            availabilityConstants.withNamespace(availabilityConstants.action.CLEAR_FILTERS)
          )
          this.$store.dispatch(
            projectConstants.withNamespace(projectConstants.action.RESET_SURFACE_FIELDS)
          )
          this.clearSelectedSpace()
        }
        if (this.isFloorFocus || this.isSpaceFocus) {
          this.$store.dispatch(
            availabilityConstants.withNamespace(availabilityConstants.action.UPDATE_FILTERS),
            {
              floorId: undefined,
              floorNo: undefined
            }
          )
          this.clearSelectedSpace()
        }
      }
    },
    // eslint-disable-next-line require-await
    async clearSelectedSpace() {
      return this.$store.dispatch('building/viewSpace', {})
    },
    async initializeBuilding3D() {
      let highlightValues = []
      if (this.projectSettings.building.highlightValues) {
        highlightValues = this.projectSettings.building.highlightValues
      } else {
        this.buildings.forEach((building) => {
          highlightValues.push({
            building: building.code,
            highlight: {
              highlightAlpha:
                this.projectSettings.building.highlightAlpha || this.projectSettings.building.alpha,
              highlightBeta:
                this.projectSettings.building.highlightBeta || this.projectSettings.building.beta
            }
          })
        })
      }

      const payload = {
        pinsData: this.projectSettings.building.pinsData,
        client: this.$config.CLIENT,
        project: this.$store.state.project.project,
        cdnBase: this.cdnBase,
        activeProject: this.activeProject,
        settings: this.projectSettings
      }
      this.$store.dispatch('building/destroy')
      this.$store.dispatch('building/initBuilding', payload)
      // Init scene
      const defaultConfig3D = CLIENT_CONFIG_3D[this.$config.CLIENT].building
      const modelPath = `objects/${this.$config.CLIENT}/${this.$store.getters.getActiveProjectData.slug}/project`

      let activeHighlights = await this.getAllProjectSpaces()
      activeHighlights = activeHighlights.map((space) => ({
        available: space.available,
        space_code: space.space_code,
        building_code: space.floor?.building?.code,
        floor_code: space.floor?.code,
        id: space.id
      }))

      let payload3D = {
        ...Client3DManager.DEFAULT_BUILDING_PAYLOAD,
        cdnBase: this.cdnBase,
        activeProject: modelPath,
        ...this.projectSettings.building,
        pinsData: this.pinConfigBuilding.pins,
        pinsContentCDN: this.cdnBase,
        highlightValues,
        activeHighlights
      }

      if (this.projectSettings.building.caseSettings) {
        payload3D = Object.assign(payload3D, this.projectSettings.building.caseSettings)
      }

      const clientManager = this.engine3d.default()
      clientManager.onSceneReady = () => {
        this.$store.commit('building/setLoadingAssetsStatus', true)
        this.$store.commit('building/setLoadingScreenStatus', false)

        clientManager.onAfterRender(() => {
          if (window.innerWidth < 1200) {
            clientManager.showPinsByCategory('none')
          } else {
            clientManager.showPinsByCategory('')
          }
        }, 100)

        if (defaultConfig3D.includes(Config3D.buildingInteractionManager) && this.isMenuOpen) {
          const elementHalfWidth = getElementHalfWidth('#organism-sidebar-project')
          const offsetValueX = this.isMenuOpen && window.innerWidth > 900 ? -elementHalfWidth : 0
          const offsetValueY = window.innerWidth <= 900 ? -50 : 0
          const offsetTarget = { x: offsetValueX, y: offsetValueY }
          clientManager.cameraFocusTo('', '', offsetTarget)
        }
      }
      clientManager.onAssetLoadingError = (message) => {
        if (window && 'analyticsLayer' in window) {
          window.analyticsLayer.send({
            event: 'asset_loading_error',
            payload: {
              location: 'building',
              message
            }
          })
        }
        this.$store.commit('building/setAssetLoadingError', message)
      }
      clientManager.onContextLostEvent = () => {
        if (window && 'analyticsLayer' in window) {
          window.analyticsLayer.send({
            event: 'webgl_context_lost',
            payload: {}
          })
        }
        this.$store.commit('building/setWebGLContextLost', true)
      }
      clientManager.onContextRestoredEvent = () => {
        setTimeout(() => {
          if (window && 'analyticsLayer' in window) {
            window.analyticsLayer.send({
              event: 'webgl_context_restored',
              payload: {}
            })
          }
          this.$store.commit('building/setWebGLContextLost', false)
        }, 5 * 1000)
      }
      clientManager.setSceneNodeNames = () => {
        const sceneNodeNames = {}
        const buildingNames = []
        for (const building of this.buildings) {
          buildingNames.push(building.code.toLowerCase())
        }
        sceneNodeNames.buildingCodeNames = buildingNames

        const phaseNames = []
        for (const phase of this.phases) {
          phaseNames.push(phase.code.toLowerCase())
        }
        sceneNodeNames.buildingCodeNames = buildingNames
        sceneNodeNames.phaseCodeNames = phaseNames

        return sceneNodeNames
      }
      await clientManager.init(payload3D, this.$refs.canvas, defaultConfig3D)
      clientManager.load(LOAD_TYPE.BUILDING)
    },
    async initializeFloor3D(space) {
      if (!space) return
      if (space.tours && space.tours.length) {
        window.vueStore = this.$store
        this.$store.dispatch(
          guidedTourConstants.withNamespace(guidedTourConstants.action.SET_AVAILABLE_TOURS),
          space.tours
        )
      }

      const building = this.buildings.find((b) => b.id === space.building_id)
      const payload = {
        pinsData: this.projectSettings.floor.pinsData,
        canvasReference: this.$refs.canvas,
        space: space.space_code,
        building: space.floor.building.code.toLowerCase(),
        floor: space.floor.code,
        floorType: space.floor.floor_type.model,
        spaceData: space,
        client: this.$config.CLIENT,
        project: this.$store.state.project.project,
        cdnBase: this.cdnBase,
        activeProject: this.activeProject,
        settings: this.projectSettings
      }
      this.$store.dispatch('building/destroy')
      this.$store.dispatch('building/viewSpace', payload)
      this.$store.dispatch('building/initFloor', payload)
      // Init scene
      const modelPath = `objects/${this.$config.CLIENT}/${this.$store.getters.getActiveProjectData.slug}`

      const hasCameraCoordinates = space.camera_coordinates?.length > 0
      console.log(' hasCameraCoordinates ', hasCameraCoordinates)
      const initialCoords = hasCameraCoordinates ? space.camera_coordinates[0] : space

      let defaultConfig3D = [
        // Config3D.loggingEnabled,
        Config3D.hdr,
        Config3D.skybox,
        Config3D.pointerManager,
        Config3D.fitoutsManager,
        Config3D.minimap,
        Config3D.fastTravelManager,
        Config3D.multipleLevelsManager,
        Config3D.lightmapFromAO
      ]

      const buildingCode = building.code.split('_')[0]
      // new project - new structure
      const newFloorStructure = this.projectSettings.floor.newFloorStructure || false
      if (!newFloorStructure) {
        defaultConfig3D.push(Config3D.resiLegacy)
      }

      if (this.projectSettings.floor.removedFeatures) {
        defaultConfig3D = defaultConfig3D.filter(
          (config) => !this.projectSettings.floor.removedFeatures.includes(config)
        )
      }

      let skyboxTexture = this.projectSettings.floor.skyboxTexture
      if (this.projectSettings.floor.multipleSkyboxTexture) {
        skyboxTexture = skyboxTexture + '/' + floor.code
      }

      const payload3D = {
        ...Client3DManager.DEFAULT_FLOOR_PAYLOAD,
        cdnBase: this.cdnBase,
        activeProject: `${modelPath}/${building.code}/${space.space_code}`,
        floorCode: space.floor.floor_type.model.split('.gltf')[0].toLowerCase(),
        startPosition: {
          x: initialCoords.camera_position_x,
          y: initialCoords.camera_position_y,
          z: initialCoords.camera_position_z
        },
        target: {
          x: initialCoords.focus_target_x,
          y: initialCoords.focus_target_y,
          z: initialCoords.focus_target_z
        },
        cameraCoordinates: space.camera_coordinates,
        ...this.projectSettings.floor,
        pinsData: this.pinConfigFloor.pins,
        pinsContentCDN: this.cdnBase,
        skyboxTexture
      }

      const clientManager = this.engine3d.default()
      clientManager.onSceneReady = () => {
        if (this.projectSettings.floor.initWithoutFitout) {
          clientManager.onAfterRender(() => {
            clientManager.toggleFitout()
          }, 1)
        }
        if (this.disableWalkThrough) {
          clientManager.disableFirstPersonCameraGizmo()
        }

        /// Scene is ready to be displayed
        this.$store.commit('building/setLoadingAssetsStatus', true)
      }
      clientManager.onChangeViewMode = (viewMode) => {
        this.$store.dispatch('building/changeViewMode', viewMode)
      }
      clientManager.onAssetLoadingError = (message) => {
        if (window && 'analyticsLayer' in window) {
          window.analyticsLayer.send({
            event: 'asset_loading_error',
            payload: {
              location: 'floor',
              message,
              space
            }
          })
        }
        this.$store.commit('building/setAssetLoadingError', message)
      }
      clientManager.onContextLostEvent = () => {
        if (window && 'analyticsLayer' in window) {
          window.analyticsLayer.send({
            event: 'webgl_context_lost',
            payload: {}
          })
        }
        this.$store.commit('building/setWebGLContextLost', true)
      }
      clientManager.onContextRestoredEvent = () => {
        setTimeout(() => {
          if (window && 'analyticsLayer' in window) {
            window.analyticsLayer.send({
              event: 'webgl_context_restored',
              payload: {}
            })
          }
          this.$store.commit('building/setWebGLContextLost', false)
        }, 5 * 1000)
      }
      clientManager.setSceneNodeNames = () => {
        const sceneNodeNames = {}
        sceneNodeNames.floorCeilingNodeName =
          this.projectSettings.floor.floorCeilingNodeName || 'ceiling_s1'
        sceneNodeNames.fitoutCode = this.projectSettings.floor.fitoutCode || 'fitouts/s1/'
        sceneNodeNames.floorLockedNode = newFloorStructure
          ? `locked_${buildingCode}_${floor.code}`
          : ''
        sceneNodeNames.floorCollisionsNode = newFloorStructure
          ? `collision_${buildingCode}_${floor.code}`
          : ''
        sceneNodeNames.cameraFocusNode = newFloorStructure ? 'fitouts' : ''
        sceneNodeNames.spacesNode = newFloorStructure ? `spaces_${floor.code}` : ''

        return sceneNodeNames
      }
      await clientManager.init(payload3D, this.$refs.canvas, defaultConfig3D)
      clientManager.load(LOAD_TYPE.FLOOR)
    },
    resizeListener() {
      this.sidebarHeight = document.getElementById('organism-sidebar-project')
        ? document.getElementById('organism-sidebar-project').clientHeight
        : 0
      this.scrollButtonHeight = document.getElementById('scroll-to-next-section')
        ? document.getElementById('scroll-to-next-section').clientHeight
        : 0
      this.windowHeight = window.innerHeight
      if (window.innerWidth > 900) {
        this.canvasHeight = this.windowHeight
      } else {
        this.canvasHeight = this.windowHeight - this.sidebarHeight - this.scrollButtonHeight
      }
      this.$nextTick(() => {
        const clientManager = this.engine3d.default()
        if (!clientManager) return
        clientManager.resize()
      })
    },
    initializeObserver() {
      const options = {
        root: null, // Use the viewport as the root element
        rootMargin: '0px', // No margin around the root element
        threshold: 0.9 // Trigger when at least 50% of the element is visible
      }
      const observer = new IntersectionObserver(this.handleIntersection, options)

      // Start observing the section
      if (this.$refs.sectionToObserve) {
        observer.observe(this.$refs.sectionToObserve)
      }
    },
    handleIntersection(entries, observer) {
      // The callback function to handle the intersection
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          // Section entered the viewport
          this.$store.dispatch(
            baseConstants.withNamespace(baseConstants.action.CHANGE_COLLAPSED_MENU),
            false
          )
        } else {
          // Section left the viewport
          this.$store.dispatch(
            baseConstants.withNamespace(baseConstants.action.CHANGE_COLLAPSED_MENU),
            true
          )
        }
      })
    }
  }
}
