import { render, staticRenderFns } from "./OrganismBuilding.vue?vue&type=template&id=4be0348a&"
import script from "./OrganismBuilding.vue?vue&type=script&lang=js&"
export * from "./OrganismBuilding.vue?vue&type=script&lang=js&"
import style0 from "./OrganismBuilding.vue?vue&type=style&index=0&id=4be0348a&prod&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {OrganismsProjectBuildingV2OrganismPins: require('/app/components/organisms/project/buildingV2/OrganismPins.vue').default,OrganismsProjectBuildingV2OrganismBuildingLabels: require('/app/components/organisms/project/buildingV2/OrganismBuildingLabels.vue').default,AtomsCommonAtomContextLostLoading: require('/app/components/atoms/common/AtomContextLostLoading.vue').default})
