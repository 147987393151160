import { render, staticRenderFns } from "./OrganismBuilding.vue?vue&type=template&id=4684d0e6&"
import script from "./OrganismBuilding.vue?vue&type=script&lang=js&"
export * from "./OrganismBuilding.vue?vue&type=script&lang=js&"
import style0 from "./OrganismBuilding.vue?vue&type=style&index=0&id=4684d0e6&prod&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {OrganismsProjectOrganismPins: require('/app/components/organisms/project/OrganismPins.vue').default,OrganismsProjectOrganismHighlightSpace: require('/app/components/organisms/project/OrganismHighlightSpace.vue').default})
