import { render, staticRenderFns } from "./Organism3DContainer.vue?vue&type=template&id=012fd3b4&"
import script from "./Organism3DContainer.vue?vue&type=script&lang=js&"
export * from "./Organism3DContainer.vue?vue&type=script&lang=js&"
import style0 from "./Organism3DContainer.vue?vue&type=style&index=0&id=012fd3b4&prod&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {OrganismsProjectBuildingV2OrganismBuildingErrorOccurred: require('/app/components/organisms/project/buildingV2/OrganismBuildingErrorOccurred.vue').default,OrganismsProjectBuildingV2OrganismBuildingLoader: require('/app/components/organisms/project/buildingV2/OrganismBuildingLoader.vue').default,OrganismsProjectBuildingV2OrganismBuilding: require('/app/components/organisms/project/buildingV2/OrganismBuilding.vue').default})
